import React, { Component, Fragment, useState, useEffect, useRef, useMemo, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Input, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { mapValues, omit, pickBy, mergeWith, last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useCounter, useToggle, useDebounce, useMap, useAsync, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { ResizableBox } from 'react-resizable';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, getAllCollectionDataByChunk, } from '../../shared/firebase';
import { dimensionsOfCompany, enabledBudgetSubjectTypes, } from '../../shared/models/company';
import { breakdownItemsLimitUnit, documentTypes, budgetSubjectTypes, } from '../../shared/config';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, readFile, fullPathWithParams, trialItemsToRowItems, computeSegmentedTrialAmount, computeBudget, pickSearch, filterRowsByAmount, groupSegmentedTrials, groupSegmentedTrialSubItems, } from '../../utils';
import { fields as budgetContainerFields, } from '../../shared/models/budgetContainer';
import { canWriteNote, } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import useMainTableUtilities from '../hooks/useMainTableUtilities';
import TrialQueriesSection from '../TrialQueriesSection';
import HelpLink from '../HelpLink';
import ProgressButton from '../ProgressButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ModelFormModal from '../modals/ModelFormModal';
import CompanyPublicPage from '../hocs/CompanyPublicPage';
import CommentsDropdown from '../CommentsDropdown';
import QuerySelector from '../QuerySelector';
import HoveredNoter from '../HoveredNoter';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import Row from './CompanyMonthBudgets/Row';
import AllCollapseButton from '../AllCollapseButton';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const fetchPublicPageData = functions.httpsCallable('fetchPublicPageData', { timeout: 550000 });
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const maxContainersCount = 12;
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率', isRate: true, },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率', isRate: true, },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const aggregationTypes = {
  cumulative: { label: '累計', },
  single: { label: '単月', },
};
const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default CompanyPublicPage(CompanyPublictMonthBudgets);

function CompanyPublictMonthBudgets(props) {
  const { company, company: { sourceId: companySourceId }, user, members, period, targetMonth, match: { params: { companyId } } } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    publicKey,
    documentType = 'pl',
    dimension = 'none',
    subjectType: budgetSubjectType,
    budgetContainerId,
    comparedBudgetContainerId,
    aggregationType = 'cumulative',
    itemNamesForFilter,
    subItemNamesForFilter,
  } = queryParams;
  const {
    onClickComment,
    toggleCommenter,
    toggleInitiallyCommentRow,
    subRowsVisibilities,
    setAllSubRowsVisibilities,
    setSubRowsVisibilities,
    setHoveredCommenters,
    onRowsChanged,
  } = useMainTableUtilities();
  const [leftColumnWidth, setLeftColumnWidth] = useState(COLUMN_WIDTH * 2);
  const [allRows, setAllRows] = useState([]);
  const isSubFiltered = (queryParams.dimension || 'none') !== 'none' && queryParams.isSubFilter === '1';
  const rows = useMemo(_ => allRows.filter(_ => (_.accountItemCategory?.type === documentType && !_.accountItemCategory?.hidden) || _.isCustom), [allRows, documentType]);
  const [version, { inc: updateVersion }] = useCounter([]);
  const [subRowsBreakdownItemsLimitRates, { set: setSubRowsBreakdownItemsLimitRates }] = useMap({});
  const { freeeCollectionName, label: budgetSubjectTypeLabel, idName: subjectIdName, trialsCollectionName, freeeSubjectIdsName, collectionName: subjectCollectionName, budgetContainerCollectionName, budgetCollectionName, budgetContainerIdName, } = budgetSubjectTypes[budgetSubjectType];
  const dimensionOptions = entries(dimensionsOfCompany(company)).filter(_ => !['sections', 'segment1s', 'segment2s', 'segment3s'].filter(_ => (budgetSubjectType + 's') === _).includes(_[0])).map(([k, v]) => ({ label: v, value: k }));
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const isComparing = !isEmpty(comparedBudgetContainerId);
  const [isSettingRows, toggleSettingRows] = useToggle(false);
  const [isFetchingLastTrials, toggleFetchingLastTrials] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [showsModal, toggleModal] = useToggle();
  const { start_date: startDate, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const prevYearMonths = range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _)));
  const currentYearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const { value: data } = useAsync(async () => {
    const { data } = await fetchPublicPageData({ screenType: 'monthlyBudgets', subjectType: budgetSubjectType, publicKey, period, targetMonth, documentType, dimension, budgetContainerId, comparedBudgetContainerId, trialStart: formatDate(prevYearMonths[0], 'YYYY-MM-DD'), trialEnd: formatDate(last(currentYearMonths), 'YYYY-MM-DD'), });
    return data;
  }, [publicKey, budgetSubjectType, period, targetMonth, documentType, dimension, budgetContainerId, comparedBudgetContainerId, version]);
  const { budgetSubjects, freeeSubjects, customAccountItems, accountItems, budgetContainers, budgets, comparedBudgets, trials, trialSubItems, notes, screenQueries, accountItemsOrderSetting, } = useMemo(() => {
    const { budgetSubjects, freeeSubjects = [], customAccountItems = [], accountItems = [], budgetContainers = [], budgets = [], comparedBudgets = [], trials = [], trialSubItems = {}, comments = [], notes = [], screenQueries = [], accountItemsOrderSetting, } = data || {};
    const _trialSubItems = entries(trialSubItems).flatMap(([closingDate, _]) => entries(_).flatMap(([itemKey, _]) => entries(_).flatMap(([subjectId, _]) => _.map(([name, closing_balance]) => ({ name, closing_balance, subjectId, itemKey, closingDate })))));
    return { budgetSubjects, freeeSubjects, customAccountItems, accountItems, budgetContainers, budgets, comparedBudgets, trials, trialSubItems: _trialSubItems, comments, notes, screenQueries, accountItemsOrderSetting, };
  }, [data]);
  const freeeSubjectsById = keyBy(freeeSubjects, 'id');
  const sortedBudgetContainers = orderBy(budgetContainers, 'index');
  const budgetsGroupedByClosingDate = groupBy(budgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
  const comparedBudgetsGroupedItemKey = groupBy(comparedBudgets, 'itemKey');
  const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubjectRowsGroupedByItemKey = groupSegmentedTrials(trials.flatMap(t => t[freeeCollectionName].map(_ => ({ ...t, ...omit(_, 'id'), subjectId: _.id, }))));
  const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubItemsGroupedByItemKey = groupSegmentedTrialSubItems(trialSubItems);
  const commentsGroupedByCommenteeKey = groupBy([], 'commenteeKey');
  const notesByKey = keyBy(notes, 'noteKey');
  const toggleTab = (documentType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, documentType, }));
  };
  const relatedCustomAccountItems = customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);

  const filterRows = (rows) => {
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(dimension === 'none') {
      entries(metrics).map(([metric, { isRate }]) => {
        filteredRows = filterRowsByAmount(filteredRows, queryParams, metric, _ => _.column[metric] * (isRate ? 100 : 1));
      });
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          isSubFiltered && entries(metrics).map(([metric, { isRate }]) => {
            filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, metric, _ => _.column[metric] * (isRate ? 100 : 1));
          });
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                column: ((column) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.column.amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.column.prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.column.budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.column.comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.column.estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                })(mainRow.column),
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  };
  const filteredRows = filterRows(rows);

  const budgetContainerOptions = sortedBudgetContainers.map(_ => ({ label: _.name, value: _.id }));
  const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
  const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));
  const generateMonthsItems = (options) => {
    const { budgetsGroupedByClosingDate } = options;
    return [prevYearMonths, currentYearMonths].map((months) => {
      return months.map((closingDate) => {
        const balances = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = budgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const trialSubItems = (options?.trialSubItemsGroupedByClosingDate || trialSubItemsGroupedByClosingDate)[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
          dimension,
        };
      });
    });
  };
  const monthsItems = generateMonthsItems({ budgetsGroupedByClosingDate });
  const prevYearMonthItemsByMonth = keyBy(monthsItems[0], _ => formatDate(_.closingDate, 'MM'));
  const generateRows = () => {
    const items = trialItemsToRowItems(monthsItems.flat(), { targetMonth, accountItems, customAccountItems: relatedCustomAccountItems, screenType: 'sectionBudgets', documentType, itemType: documentType, dimension, budgetSubjectsFreeeName: freeeCollectionName, sortsAllPlAndCrCategories: true, accountItemsOrderSetting, })
    const mainItems = items.filter(_ => !_.isCategory && !_.isSubRow && !_.isCustom);
    const rows = items
      .map(itemToRow.bind(null, mainItems, { budgetSubjects, budgetsGroupedByItemKey, trialSubItemsGroupedByItemKey, }))
      .filter(_ => abs(_.column.amount) > 0 || abs(_.column.budgetAmount) > 0 || abs(_.column.prevYearAmount) > 0);
    return entries(groupBy(
      Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
        return [mainItem, ...orderBy(subItems, _ => _.column.amount, 'desc')];
      }).flat()
    , 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const _subRows = subRows.map((row, i) => {
          return {
            ...row,
            breakDownItemIndex: i,
            subItemsCount: subRows.length,
          };
        });
        return [
          ...x,
          { ...mainRow, subRows: _subRows },
          ..._subRows
        ];
      }, []);
  };
  const computeOverMonth = (date) => {
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  };
  const isWithinTargetMonth = (date) => {
    return ({
      cumulative: date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
      single: startOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)) <= date && date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
    })[aggregationType];
  };
  const itemToRow = (mainItems, options, item) => {
    const { isCustom = false, accountItemCategory, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const _trialSubItemsGroupedByItemKey = options.trialSubItemsGroupedByItemKey || trialSubItemsGroupedByItemKey;
    const accountCategoryName = accountItemName ? null : item.account_category_name || item.accountCategoryName;
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const _budgetSubjects = options.budgetSubjects || budgetSubjects;
    const _budgetsGroupedByItemKey = options.budgetsGroupedByItemKey || budgetsGroupedByItemKey;
    const targetRangeMonths = currentYearMonths.filter(_ => isWithinTargetMonth(_));
    const outRangeMonths = currentYearMonths.filter(_ => !isWithinTargetMonth(_));
    const prevYearTargetRangeMonths = targetRangeMonths.map(_ => addMonths(_, -12));
    const column = (() => {
      const key = `${rowKey}_${targetMonth}`;
      const budgetSubjectRows = _budgetSubjects.map((budgetSubject) => {
        const freeeSubjects = budgetSubject?.[freeeSubjectIdsName].map(_ => freeeSubjectsById[_]).filter(_ => _) || [];
        const commenteeKey = [rowKey, budgetSubject?.id, targetMonth].join('_');
        const noteKey = [rowKey, budgetSubject?.id, targetMonth].join('_');
        const amount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, budgetSubject?.[freeeSubjectIdsName], [budgetSubject?.id]);
        const prevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, prevYearTargetRangeMonths, customAccountItems, budgetSubject?.[freeeSubjectIdsName], [budgetSubject?.id]);
        const budgetAmount = computeBudget(budgetSubjectType, item, _budgetsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, [budgetSubject?.id], mainItems);
        const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, targetRangeMonths, customAccountItems, [budgetSubject?.id], mainItems);
        const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
        const outRangeBudgetAmount = computeBudget(budgetSubjectType, item, _budgetsGroupedByItemKey, dimension, outRangeMonths, customAccountItems, [budgetSubject?.id], mainItems);
        const budgetDiff = amount - budgetAmount;
        const achievementRate = amount / budgetAmount;
        const estimatedAmount = ({
          cumulative: _ => amount + outRangeBudgetAmount,
          single: _ => amount,
        })[aggregationType]();
        const prevYearDiff = amount - prevYearAmount;
        const prevYearDiffRate = prevYearDiff / prevYearAmount;
        const note = (notesByKey[noteKey] || {}).value;
        return { budgetSubject, key, commenteeKeys: [commenteeKey], noteKeys: [noteKey], amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, freeeSubjects, amountBase, };
      });
      const budgetSubjectsSummaryRow = {
        key: `${rowKey}_${targetMonth}_summary`,
        isSummaryColumn: true,
        label: '合計',
        amount: sumBy(budgetSubjectRows, 'amount'),
        budgetAmount: sumBy(budgetSubjectRows, 'budgetAmount'),
        comparedBudgetAmount: sumBy(budgetSubjectRows, 'comparedBudgetAmount'),
        diffBetweenBudgets: sumBy(budgetSubjectRows, 'diffBetweenBudgets'),
        budgetDiff: sumBy(budgetSubjectRows, 'budgetDiff'),
        achievementRate: sumBy(budgetSubjectRows, 'amount') / sumBy(budgetSubjectRows, 'budgetAmount'),
        estimatedAmount: sumBy(budgetSubjectRows, 'estimatedAmount'),
        prevYearAmount: sumBy(budgetSubjectRows, 'prevYearAmount'),
        prevYearDiff: sumBy(budgetSubjectRows, 'prevYearDiff'),
        prevYearDiffRate: sumBy(budgetSubjectRows, 'prevYearDiff') / sumBy(budgetSubjectRows, 'prevYearAmount'),
      };
      const freeeSubjects = budgetSubjectRows.flatMap(_ => _.freeeSubjects);
      const commenteeKeys = budgetSubjectRows.map(_ => _.commenteeKeys[0]);
      const noteKeys = budgetSubjectRows.map(_ => _.noteKeys[0]);
      const [amountBase, amount, prevYearAmount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, estimatedAmount, prevYearDiff] = ['amountBase', 'amount', 'prevYearAmount', 'budgetAmount', 'comparedBudgetAmount', 'diffBetweenBudgets', 'budgetDiff', 'estimatedAmount', 'prevYearDiff'].map(_ => sumBy(budgetSubjectRows, _));
      const achievementRate = amount / budgetAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const notes = noteKeys.map(_ => (notesByKey[_] || {}).value);

      const freeeSubjectValuesById = keyBy(budgetSubjects.flatMap(_ => _?.[freeeSubjectIdsName]).map((freeeSubjectId) => {
        const amount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, [freeeSubjectId], []);
        const prevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, prevYearTargetRangeMonths, customAccountItems, [freeeSubjectId], []);
        return { freeeSubjectId, amount, prevYearAmount, };
      }), 'freeeSubjectId');

      return { key, commenteeKeys, noteKeys, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, notes, note: notes.join('\n'), freeeSubjects, budgetSubjectRows, budgetSubjectsSummaryRow, freeeSubjectValuesById, targetRangeMonths, };
    })();
    const comments = column.commenteeKeys.flatMap(_ => commentsGroupedByCommenteeKey[_] || []);

    return {
      ...item,
      budgetSubjects: _budgetSubjects,
      accountItemCategory,
      accountItemName,
      accountCategoryName,
      hierarchyLevel,
      isCategory,
      isCustom,
      itemName,
      itemKey,
      mainRowItemKey,
      key: rowKey,
      subItemName,
      isSubRow,
      rowName,
      column,
      displayExpression,
      comments,
    };
  }
  const rowsDependencies = [budgetSubjects?.map(_ => _.id).join(''), budgetContainerId, comparedBudgetContainerId, trials, budgets, targetMonth, aggregationType];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setAllRows(generateRows());
    toggleSettingRows(false);
  }, 500, rowsDependencies);
  useDebounce(() => {
    const path = fullPathWithParams({
      dimension: dimension || 'none',
      aggregationType: aggregationType || 'cumulative',
      budgetContainerId: sortedBudgetContainers.some(_ => _.id === budgetContainerId) ? budgetContainerId : get(sortedBudgetContainers, [0, 'id']),
    }, location);
    history.replace(encodeURI(path));
  }, 1000, [documentType, budgetContainers]);
  useEffect(() => {
    toggleInitiallyCommentRow(rows);
    onRowsChanged();
  }, [rows]);

  return (
    <div className="company-monthly-budgets">
      <div className="px-3 py-3">
        <div className="d-flex justify-content-end mb-2">
          <HelpLink text="予実管理を行う" />
        </div>
        <div className="mb-2">
          <h3 className="text-center">予実</h3>
          <h5 className="text-center text-muted">{budgetSubjects?.map(_ => _.name).join(', ')}</h5>
        </div>
        <Nav tabs>
          {
            entries(pick(documentTypes, ['pl', 'cr'])).map(([_documentType, { label: documentTypeLabel }]) => {
              return (
                <NavItem key={_documentType}>
                  <NavLink className={classnames('cursor-pointer', { active: _documentType === documentType })} onClick={toggleTab.bind(null, _documentType)}>
                    {documentTypeLabel}
                  </NavLink>
                </NavItem>
              );
            })
          }
        </Nav>
        <div className="py-3">
          <div className="mb-3 d-flex justify-content-between align-items-end">
            <TrialQueriesSection 
              company={company}
              readOnly
              screenQueries={screenQueries.filter(_ => _.screenType === 'monthBudgets')}
              screenType="monthBudgets"
              uncontrols={['budgetSubjectId']}
              items={[
                [
                  {
                    name: 'itemNamesForFilter',
                    type: 'multiSelect',
                    options: itemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                  {
                    name: 'dimension',
                    type: 'select',
                    options: dimensionOptions,
                    label: '明細',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
                [
                  ...(
                    dimension !== 'none' ? [
                      {
                        name: 'isSubFilter',
                        type: 'boolean',
                        label: '明細単位で検索する',
                      },
                    ] : []
                  ),
                  ...(
                    dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                      {
                        name: 'subItemNamesForFilter',
                        type: 'multiSelect',
                        options: subItemNameOptions,
                        label: '明細名',
                        props: { width: 300, }
                      },
                    ] : []
                  ),
                ],
                [
                  {
                    name: 'budgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'comparedBudgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '比較予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'aggregationType',
                    type: 'select',
                    options: aggregationTypeOptions,
                    label: '集計種別',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
                ...entries(metrics).map(([metric, { label, isRate }]) => {
                  return [
                    {
                      name: metric + 'Min',
                      type: 'numeric',
                      label: label + '下限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                    {
                      name: metric + 'Max',
                      type: 'numeric',
                      label: label + '上限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                  ];
                }),
              ]}
            />
            <div className="d-flex align-items-end">
            </div>
          </div>
          {
            budgetSubjects != null && (
              <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
                <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
                  <thead className="text-center" style={{ lineHeight: '20px' }}>
                    <tr>
                      <th className="text-left border-right p-0">
                        <ResizableBox className="p-2 d-flex align-items-center" width={COLUMN_WIDTH * 2} height={44} axis="x" resizeHandles={['e']} onResize={(e, _) => setLeftColumnWidth(_.size.width)}>
                          {
                            dimension !== 'none' && (
                              <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={subRowsVisibilities} setVisibilities={setAllSubRowsVisibilities} />
                            )
                          }
                          &nbsp;
                        </ResizableBox>
                      </th>
                      {
                        entries(omit(metrics, !isComparing && ['comparedBudgetAmount', 'diffBetweenBudgets'])).map(([metric, { label: metricLabel, }]) => {
                          return (
                            <th key={metric} className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                              {metricLabel}
                            </th>
                          );
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredRows.map((row) => {
                        const { isSubRow, itemKey, } = row;
                        const showsSubRows = (dimension !== 'none' && !!subRowsVisibilities[itemKey]);
                        if(isSubRow && !showsSubRows) return null;

                        const breakdownItemsLimitRate = subRowsBreakdownItemsLimitRates[itemKey] || 1;
                        const toggleSubRows = setSubRowsVisibilities.bind(null, itemKey, !showsSubRows);
                        const showMoreSubRows = setSubRowsBreakdownItemsLimitRates.bind(null, itemKey, breakdownItemsLimitRate + 1);
                        return <Row isPublic updateVersion={updateVersion} pageProps={props} row={row} {...{ company, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, leftColumnWidth, }} />
                      })
                    }
                  </tbody>
                </Table>
                <OverlayLoading isOpen={isSettingRows} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

