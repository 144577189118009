import React, { Component, Fragment, useState, useEffect, useRef, useMemo, } from 'react';
import { Table, Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { omit, pickBy, mergeWith, last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useToggle, useDebounce, useMap, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { TextEncoder, } from 'text-encoding';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { dimensionsOfCompany, enabledBudgetSubjectTypes, } from '../../shared/models/company';
import { allDimensions, breakdownItemsLimitUnit, documentTypes, budgetSubjectTypes, } from '../../shared/config';
import { mapKeysToJa, jas, } from '../../shared/texts';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, fullPathWithParams, pickSearch, trialItemsToRowItems, computeSegmentedTrialAmount, computeBudget, filterRowsByAmount, groupSegmentedTrials, groupSegmentedTrialSubItems, } from '../../utils';
import { serial, } from '../../shared/util';
import { canWriteNote, } from '../../abilities';
import { getCollectionData, getAllCollectionDataByChunk, } from '../../shared/firebase';
import env from '../../env';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import useMainTableUtilities from '../hooks/useMainTableUtilities';
import TrialQueriesSection from '../TrialQueriesSection';
import HelpLink from '../HelpLink';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ModelFormModal from '../modals/ModelFormModal';
import CompanyPage from '../hocs/CompanyPage';
import CommentsDropdown from '../CommentsDropdown';
import HoveredCommenter from '../HoveredCommenter';
import QuerySelector from '../QuerySelector';
import HoveredNoter from '../HoveredNoter';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import AllCollapseButton from '../AllCollapseButton';
import Row from './CompanyBudgets/Row';
import BudgetButton from '../BudgetButton';
import ExportButton from '../ExportButton';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率' },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率' },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const aggregationTypes = {
  cumulative: { label: '累計', },
  single: { label: '単月', },
};
const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default CompanyPage(CompanyBudgets);

function CompanyBudgets(props) {
  const { accountItemsOrderSetting, accountItems, customAccountItems, company, user, members, period, targetMonth, match: { params: { companyId } } } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    tabKey = 'section__pl',
    dimension = 'none',
    aggregationType = 'cumulative', 
    budgetSubjectIds,
    budgetContainerId,
    comparedBudgetContainerId,
    itemNamesForFilter,
    subItemNamesForFilter,
  } = queryParams;
  const {
    onClickComment,
    toggleCommenter,
    toggleInitiallyCommentRow,
    subRowsVisibilities,
    setAllSubRowsVisibilities,
    setSubRowsVisibilities,
    setHoveredCommenters,
    onRowsChanged,
  } = useMainTableUtilities();
  const [leftColumnWidth, setLeftColumnWidth] = useState(COLUMN_WIDTH * 2.5);
  const [currentYearTrial, setCurrentYearTrial] = useState({});
  const [prevYearTrial, setPrevYearTrial] = useState({});
  const [allRows, setAllRows] = useState([]);
  const isSubFiltered = (queryParams.dimension || 'none') !== 'none' && queryParams.isSubFilter === '1';
  const openItemKeys = useMemo(_ => {
    return ((queryParams.dimension || 'none') === 'none' || isSubFiltered) ? [] : keys(pickBy(subRowsVisibilities, _ => _));
  }, [queryParams.dimension, subRowsVisibilities, isSubFiltered]);
  const [subRowsBreakdownItemsLimitRates, { set: setSubRowsBreakdownItemsLimitRates }] = useMap({});
  const [budgetSubjectType, documentType] = tabKey.split('__');
  const filterForScreen = _ => (_.accountItemCategory?.type === documentType && !_.accountItemCategory?.hidden) || _.isCustom;
  const rows = useMemo(_ => allRows.filter(filterForScreen), [allRows, documentType]);
  const { freeeCollectionName, label: budgetSubjectTypeLabel, idName: subjectIdName, trialsCollectionName, freeeSubjectIdsName, collectionName: subjectCollectionName, budgetContainerCollectionName, budgetCollectionName, budgetContainerIdName, } = budgetSubjectTypes[budgetSubjectType];
  const dimensionOptions = entries(dimensionsOfCompany(company)).filter(_ => !['sections', 'segment1s', 'segment2s', 'segment3s'].filter(_ => (budgetSubjectType + 's') === _).includes(_[0])).map(([k, v]) => ({ label: v, value: k }));
  const [isSettingRows, toggleSettingRows] = useToggle();
  const [isFetchingLastTrials, toggleFetchingLastTrials] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [showsModal, toggleModal] = useToggle();
  const { start_date: startDate, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const prevYearMonths = range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _)));
  const currentYearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const budgetSubjects = useCollectionSubscription(company.ref.collection(subjectCollectionName).orderBy('createdAt'), [budgetSubjectType]);
  const sortedBudgetSubjects = sortBy(budgetSubjects || [], _ => _.index != null ? _.index : (budgetSubjects || []).indexOf(_));
  const budgetContainers = useCollectionSubscription(companiesRef.doc(companyId).collection('budgetContainers').orderBy('index'));
  const freeeSubjects = useCollectionSubscription(companiesRef.doc(companyId).collection(freeeCollectionName), [budgetSubjectType]);
  const freeeSubjectsById = keyBy([{ id: 0, name: '未選択' }, ...freeeSubjects], 'id');
  const uniqBudgetContainers = uniqBy(orderBy(budgetContainers, _ => _.createdAt.toDate(), 'desc'), subjectIdName);
  const uniqRelatedBudgetContainerIds = uniqBudgetContainers.filter(_ => (budgetSubjectIds || []).includes(_[subjectIdName])).map(_ => _.id);
  const budgets = useCollectionSubscription(budgetContainerId && companiesRef.doc(companyId).collection('budgets').where('period', '==', period).where('budgetContainerId', '==', budgetContainerId).where('budgetSubjectType', '==', budgetSubjectType).where('dimension', '==', dimension), [period, budgetContainerId, dimension]);
  const budgetsGroupedByClosingDate = groupBy(budgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
  const comparedBudgets = useCollectionSubscription(comparedBudgetContainerId && companiesRef.doc(companyId).collection('budgets').where('period', '==', period).where('budgetContainerId', '==', comparedBudgetContainerId).where('budgetSubjectType', '==', budgetSubjectType).where('dimension', '==', dimension), [period, comparedBudgetContainerId, dimension]);
  const comparedBudgetsGroupedItemKey = groupBy(comparedBudgets, 'itemKey');
  const budgetSubjectsById = keyBy(sortedBudgetSubjects, 'id');

  const trialsRef = (documentType) => {
    return company.ref
      .collection(trialsCollectionName + suffix(documentType))
        .where('dimension', '==', 'none')
        .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
        .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'));
  };
  const plTrials = useCollectionSubscription(trialsRef('pl'), [tabKey, dimension, period]);
  const crTrials = useCollectionSubscription(trialsRef('cr'), [tabKey, dimension, period]);
  const trials = useMemo(_ => [...plTrials, ...crTrials], [plTrials, crTrials]);
  const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubjectRowsGroupedByItemKey = groupSegmentedTrials(trials.flatMap(t => (t[freeeCollectionName] || []).map(_ => ({ ...t, ...omit(_, 'id'), subjectId: _.id, }))));

  const subItemCollectionName = trialsCollectionName + 'SubItemChunks__' + dimension;
  const subItemRefs = useMemo(_ =>
    isSubFiltered ? (
      [company.ref.collection(subItemCollectionName)]
    ) : chunk(openItemKeys, 10).map(_ => company.ref.collection(subItemCollectionName).where('itemKey', 'in', _))
  , [isSubFiltered, subItemCollectionName, openItemKeys]);
  const _trialSubItems = useCollectionsFetch(subItemRefs.map((ref) => {
    return ref
      .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'));
  }), [subItemRefs, period]);
  const trialSubItems = useMemo(_ => _trialSubItems.flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ }))), [_trialSubItems]);
  const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubItemsGroupedByItemKey = groupSegmentedTrialSubItems(trialSubItems);

  const comments = useCollectionSubscription(companiesRef.doc(companyId).collection('comments').where('queryKey', '==', budgetCollectionName).orderBy('createdAt'));
  const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
  const notes = useCollectionSubscription(companiesRef.doc(companyId).collection('notes').where('queryKey', '==', budgetCollectionName));
  const notesByKey = keyBy(notes, 'noteKey');
  const toggleTab = (tabKey) => {
    const [targetBudgetSubjectType] = tabKey.split('__');
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, tabKey, ...(budgetSubjectType !== targetBudgetSubjectType && { budgetSubjectIds: null, }), }));
  };
  const relatedCustomAccountItems = customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
  const filteredBudgetSubjects = (budgetSubjectIds || []).map(_ => budgetSubjectsById[_]).filter(_ => _);
  const budgetSubjectOptions = sortedBudgetSubjects.map(_ => ({ label: _.name, value: _.id }));
  const budgetContainerOptions = budgetContainers.map(_ => ({ label: _.name, value: _.id }));
  const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
  const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));

  const fetchLastTrials = async () => {
    toggleFetchingLastTrials(true);
    const token = await auth.currentUser.getIdToken(true);
    const closingDate = endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
    const prevYearClosingDate = endOfMonth(addYears(closingDate, -1));
    try {
      const [currentYearTrial, prevYearTrial] = await Promise.all([closingDate, prevYearClosingDate].filter(_ => existsInFiscalYears(_, company)).map(async (closingDate) => {
        const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeTrial?${qs.stringify({ type: documentType, companyId, companySourceId: company.sourceId, startDate: formatDate(startOfMonthByFiscalYears(closingDate, company), 'YYYY-MM-DD'), endDate: formatDate(closingDate, 'YYYY-MM-DD'), dimension: 'none', })}`;
        const headers = { 'Firebase-User-Token': token };
        const res = await fetch(url, { headers });
        if(res.status !== 200) {
          const { errors } = await res.json();
          throw new Error(errors.join('\n'));
        }
        const balances = await res.json() || [];
        return { closingDate, balances, balancesByItemKey: keyBy(balances, 'itemKey') };
      }));
      setCurrentYearTrial(currentYearTrial);
      setPrevYearTrial(prevYearTrial);
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
    toggleFetchingLastTrials(false);
  };
  const monthsItems = (options) => {
    return [prevYearMonths, currentYearMonths].map((months) => {
      return months.map((closingDate) => {
        const balances = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = budgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const trialSubItems = (options?.trialSubItemsGroupedByClosingDate || trialSubItemsGroupedByClosingDate)[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
          dimension,
        };
      });
    });
  };
  const generateRows = (options = {}) => {
    const { isAll = false, } = options;
    const trialSubItemsGroupedByClosingDate = options?.trialSubItems && groupBy(options.trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
    const trialSubItemsGroupedByItemKey = options?.trialSubItems && groupSegmentedTrialSubItems(options.trialSubItems);
    const items = trialItemsToRowItems(monthsItems({ trialSubItemsGroupedByClosingDate }).flat(), { targetMonth, accountItems, customAccountItems: relatedCustomAccountItems, screenType: 'sectionsBudgets', documentType, itemType: documentType, dimension, budgetSubjectsFreeeName: freeeCollectionName, sortsAllPlAndCrCategories: true, accountItemsOrderSetting, })
    const mainItems = items.filter(_ => !_.isCategory && !_.isSubRow && !_.isCustom);
    const rows = items
      .map(itemToRow.bind(null, mainItems, { isAll, budgetsGroupedByItemKey, trialSubItemsGroupedByItemKey, }))
      .filter(_ => _.columns.some(_ => abs(_.amount) > 0 || abs(_.budgetAmount) > 0 || abs(_.prevYearAmount) > 0));
    return entries(groupBy(
      Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
        return [mainItem, ...orderBy(subItems, [_ => Math.abs(sumBy(_.columns, 'amount')), _ => Math.abs(sumBy(_.columns, 'budgetAmount'))], ['desc', 'desc'])];
      }).flat()
    , 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        return [
          ...x,
          mainRow,
          ...subRows.map((row, i) => {
            return {
              ...row,
              breakDownItemIndex: i,
              subItemsCount: subRows.length,
            };
          })
        ];
      }, []);
  };
  const isWithinTargetMonth = (date) => {
    return ({
      cumulative: date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
      single: startOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)) <= date && date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
    })[aggregationType];
  };
  const itemToRow = (mainItems, options, item) => {
    const { isAll = false } = options;
    const { isCustom = false, isManual = false, accountItemCategory, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const _trialSubItemsGroupedByItemKey = options.trialSubItemsGroupedByItemKey || trialSubItemsGroupedByItemKey;
    const targetRangeMonths = currentYearMonths.filter(_ => isWithinTargetMonth(_));
    const prevYearTargetRangeMonths = targetRangeMonths.map(_ => addMonths(_, -12));
    const outRangeMonths = currentYearMonths.filter(_ => !isWithinTargetMonth(_));
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const usedFreeeSubjectIds = uniq(sortedBudgetSubjects.map(_ => _[freeeSubjectIdsName]).flat());
    const targetBudgetSubjects = isAll ? sortedBudgetSubjects : filteredBudgetSubjects;

    const columns = targetBudgetSubjects.map((budgetSubject, i) => {
      const freeeSubjects = budgetSubject[freeeSubjectIdsName].map(_ => freeeSubjectsById[_]).filter(_ => _);
      const key = `${rowKey}_${budgetSubject.id}`;
      const commenteeKey = `${key}_${targetMonth}`;
      const noteKey = `${key}_${targetMonth}`;
      const amount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, targetRangeMonths, relatedCustomAccountItems, budgetSubject[freeeSubjectIdsName], [budgetSubject.id]);
      const budgetAmount = computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, targetRangeMonths, relatedCustomAccountItems, [budgetSubject.id], mainItems);
      const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, targetRangeMonths, relatedCustomAccountItems, [budgetSubject.id], mainItems);
      const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
      const outRangeBudgetAmount = computeBudget(budgetSubjectType, item, options.budgetsGroupedByItemKey || budgetsGroupedByItemKey, dimension, outRangeMonths, relatedCustomAccountItems, [budgetSubject.id], mainItems);
      const budgetDiff = amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = ({
        cumulative: _ => amount + outRangeBudgetAmount,
        single: _ => amount,
      })[aggregationType]();
      const prevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, prevYearTargetRangeMonths, relatedCustomAccountItems, budgetSubject[freeeSubjectIdsName], [budgetSubject.id]);
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const note = (notesByKey[noteKey] || {}).value;
      return { key, commenteeKey, noteKey, budgetSubject, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, freeeSubjects, };
    });

    const totalAmount = sumBy(columns, 'amount');
    const totalUsedAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, targetRangeMonths, relatedCustomAccountItems, usedFreeeSubjectIds, false);
    const totalUsedPrevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, prevYearTargetRangeMonths, relatedCustomAccountItems, usedFreeeSubjectIds, false);

    const totalBudgetAmount = sumBy(columns, 'budgetAmount');
    const totalPrevYearAmount = sumBy(columns, 'prevYearAmount');
    const trialAmount = isSubRow ? null : get(currentYearTrial, ['balancesByItemKey', itemKey, 'closing_balance']);
    const prevYearTrialAmount = isSubRow ? null : get(prevYearTrial, ['balancesByItemKey', itemKey, 'closing_balance']);
    const diff = isSubRow ? null : totalUsedAmount - trialAmount;
    const prevYearDiff = isSubRow ? null : totalUsedPrevYearAmount - prevYearTrialAmount;
    const summaryColumns = [
      {
        isSummaryColumn: true,
        name: 'sum',
        key: `${rowKey}_sum`,
        commenteeKey: `${rowKey}_sum`,
        amount: totalAmount,
        budgetAmount: totalBudgetAmount,
        budgetDiff: totalAmount - totalBudgetAmount,
        achievementRate: totalAmount / totalBudgetAmount,
        estimatedAmount: sumBy(columns, 'estimatedAmount'),
        prevYearAmount: totalPrevYearAmount,
        prevYearDiff: totalAmount - totalPrevYearAmount,
        prevYearDiffRate: (totalAmount - totalPrevYearAmount) / totalPrevYearAmount,
      },
      {
        isSummaryColumn: true,
        name: 'uniqSum',
        key: `${rowKey}_uniqSum`,
        commenteeKey: `${rowKey}_uniqSum`,
        amount: totalUsedAmount,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: totalUsedPrevYearAmount,
        prevYearDiff: totalUsedAmount - totalUsedPrevYearAmount,
        prevYearDiffRate: (totalUsedAmount - totalUsedPrevYearAmount) / totalUsedPrevYearAmount,
      },
      {
        isSummaryColumn: true,
        name: 'trial',
        key: `${rowKey}_trial`,
        commenteeKey: `${rowKey}_trial`,
        amount: trialAmount ?? NaN,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: prevYearTrialAmount ?? NaN,
        prevYearDiff: [trialAmount, prevYearTrialAmount].every(_ => _ != null) ? trialAmount - prevYearTrialAmount : NaN,
        prevYearDiffRate: (trialAmount - prevYearTrialAmount) / prevYearTrialAmount,
      },
      {
        isSummaryColumn: true,
        name: 'diff',
        key: `${rowKey}_diff`,
        commenteeKey: `${rowKey}_diff`,
        amount: diff ?? NaN,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: prevYearDiff ?? NaN,
        prevYearDiff: NaN,
        prevYearDiffRate: NaN,
      },
    ];
    const comments = [...columns, ...summaryColumns].map(_ => commentsGroupedByCommenteeKey[_.commenteeKey] || []).flat();
    return { ...item, accountItemCategory, hierarchyLevel, isCategory, isCustom, itemName, itemKey, mainRowItemKey, key: rowKey, subItemName, isSubRow, rowName, columns, summaryColumns, displayExpression, diff, comments, };
  }
  const rowsDependencies = [(budgetSubjectIds || []).join('-'), trials, trialSubItems, budgets, comparedBudgets, targetMonth, aggregationType, currentYearTrial, prevYearTrial, documentType];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setAllRows(generateRows());
    toggleSettingRows(false);
  }, 500, rowsDependencies)

  const filterRows = (rows) => {
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(dimension === 'none') {
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                columns: mainRow.columns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.columns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.columns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.columns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.columns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
                summaryColumns: mainRow.summaryColumns.map((summaryColumn, i) => {
                  const amount = (!isSubFiltered || ['trial', 'diff'].includes(summaryColumn.name)) ? summaryColumn.amount : sumBy(filteredSubRows, _ => _.summaryColumns[i].amount);
                  const prevYearAmount = (!isSubFiltered || ['trial', 'diff'].includes(summaryColumn.name)) ? summaryColumn.prevYearAmount : sumBy(filteredSubRows, _ => _.summaryColumns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? summaryColumn.budgetAmount : sumBy(filteredSubRows, _ => _.summaryColumns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? summaryColumn.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.summaryColumns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? summaryColumn.estimatedAmount : sumBy(filteredSubRows, _ => _.summaryColumns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...summaryColumn,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  };
  const filteredRows = filterRows(rows);
  const onClickExport = async ({ isAll = false }) => {
    toggleExporting(true);

    const metricsForFilter = queryParams.metrics || keys(metrics);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const trialSubItems = dimension !== 'none' && (await getAllCollectionDataByChunk(company.ref
      .collection(subItemCollectionName)
      .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'))
    )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
    const allRows = generateRows({ isAll, trialSubItems, });
    const data = filterRows(allRows.filter(filterForScreen)).map((row) => {
      return [...metricsForFilter, ...(row.columns.every(_ => isEmpty(_.note)) ? [] : ['note'])].map((rowType) => {
        return {
          ...pick(row, ['itemName', 'subItemName']),
          rowType: jas[rowType],
          ...(
            row.columns.reduce((x, y) => {
              const { budgetSubject } = y;
              return {
                ...x,
                [budgetSubject.name]: y[rowType],
              };
            }, {})
          ),
          ...(
            row.summaryColumns.reduce((x, y) => {
              const { name } = y;
              return {
                ...x,
                [name]: y[rowType],
              };
            }, {})
          ),
        };
      });
    }).reduce((x, y) => [...x, ...y], []);
    const fileContent = encoder.encode(unparseCsv(data.map(_ => mapKeysToJa(_, { diff: '差額' }))));
    fileDownload(fileContent, `予実_${targetMonth}.csv`);
    await log(company, 'budgets', 'exportCsv', user, { period, targetMonth, });
    toggleExporting(false);
  };
  // NOTE: タブが切り替わったあと、budgetSubjectsが一旦空になるまで1周待つ必要がある。非同期にすれば解決するので、useDebounceを用いる。
  const setQueryParams = () => {
    const path = fullPathWithParams({
      dimension: dimension || 'none',
      aggregationType: aggregationType || 'cumulative',
      budgetContainerId: budgetContainers.some(_ => _.id === budgetContainerId) ? budgetContainerId : get(budgetContainers, [0, 'id']),
      ...(
        !isEmpty(budgetSubjects) && {
          budgetSubjectIds: sortedBudgetSubjects.slice(0, company.budgetSubjectDefaultDisplayCount ?? 5).map(_ => _.id),
        }
      ),
    }, location);
    history.replace(encodeURI(path));
  };
  useDebounce(setQueryParams, 1000, [tabKey?.split('__')[0], budgetContainers, budgetSubjects]);
  useEffect(() => {
    if(queryParams.dimension === undefined) setQueryParams();
  }, [queryParams]);
  useEffect(() => {
    toggleInitiallyCommentRow(rows);
    onRowsChanged();
  }, [rows]);
  const oldBudgetRows = async () => {
    const customSections = await getCollectionData(company.ref.collection('customSections'));
    const customSegment1s = await getCollectionData(company.ref.collection('customSegment1s'));
    const customSegment2s = await getCollectionData(company.ref.collection('customSegment2s'));
    const customSegment3s = await getCollectionData(company.ref.collection('customSegment3s'));
    const budgetSubjectsByName = {
      section: keyBy(customSections, 'name'),
      segment1: keyBy(customSegment1s, 'name'),
      segment2: keyBy(customSegment2s, 'name'),
      segment3: keyBy(customSegment3s, 'name'),
    };
    const budgetSubjectsById = {
      section: keyBy(customSections, 'id'),
      segment1: keyBy(customSegment1s, 'id'),
      segment2: keyBy(customSegment2s, 'id'),
      segment3: keyBy(customSegment3s, 'id'),
    };
    return (await serial(entries(enabledBudgetSubjectTypes(company)), async ([budgetSubjectType, { freeeCollectionName, label: budgetSubjectTypeLabel, idName: subjectIdName, trialsCollectionName, freeeSubjectIdsName, collectionName: subjectCollectionName, budgetContainerCollectionName, budgetCollectionName, budgetContainerIdName, }]) => {
      const budgetContainers = await getCollectionData(company.ref.collection(budgetContainerCollectionName).where('period', '==', period));
      const budgets = await getAllCollectionDataByChunk(company.ref.collection(budgetCollectionName).where('period', '==', period));
      const budgetGroupedByContainerId = groupBy(budgets, budgetContainerIdName);
      return budgetContainers.flatMap((budgetContainer) => {
        const budgetSubject = budgetSubjectsById[budgetSubjectType][budgetContainer[subjectIdName]];
        const budgets = budgetGroupedByContainerId[budgetContainer.id] || [];
        return budgets.map((budget) => {
          return {
            区分種別: budgetSubjectTypeLabel,
            区分名: budgetSubject?.name,
            予算名: budgetContainer.name,
            勘定科目: budget.accountItemName,
            明細種別: allDimensions[budgetContainer.dimension],
            明細名: budget.subItemName,
            日付: budget.closingDate,
            金額: budget.amount,
          };
        });
      });
    })).flat();
  };

  return (
    <div className="company-budgets">
      <div className="px-3 py-3">
        <div className="d-flex justify-content-end mb-2">
          <HelpLink text="予実管理を行う" />
        </div>
        <div className="mb-2">
          <h3 className="m-0 text-center">予実</h3>
        </div>
        <Nav tabs>
          {
            entries(enabledBudgetSubjectTypes(company)).flatMap(([budgetSubjectType, { label: budgetSubjectTypeLabel }]) => {
              return entries(pick(documentTypes, ['pl', 'cr'])).map(([documentType, { label: documentTypeLabel }]) => {
                const key = [budgetSubjectType, documentType].join('__');
                const label = [budgetSubjectTypeLabel, documentTypeLabel].join(' ');
                return (
                  <NavItem key={key}>
                    <NavLink className={classnames('cursor-pointer', { active: tabKey === key })} onClick={toggleTab.bind(null, key)}>
                      {label}
                    </NavLink>
                  </NavItem>
                );
              });
            })
          }
        </Nav>
        <div className="py-2">
          <div className="mb-1 d-flex justify-content-start align-items-end">
            <TrialQueriesSection 
              company={company}
              screenType="budgets"
              uncontrols={['budgetContainerId', 'comparedBudgetContainerId']}
              items={[
                [
                  {
                    name: 'budgetSubjectIds',
                    type: 'multiSelect',
                    options: budgetSubjectOptions,
                    label: budgetSubjectTypeLabel,
                    isFront: true,
                    props: { width: 500, }
                  },
                  {
                    name: 'metrics',
                    type: 'multiSelect',
                    options: metricOptions,
                    label: '数値項目',
                    props: { width: 200, }
                  },
                  {
                    name: 'itemNamesForFilter',
                    type: 'multiSelect',
                    options: itemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                  {
                    name: 'dimension',
                    type: 'select',
                    options: dimensionOptions,
                    label: '明細',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
                [
                  ...(
                    dimension !== 'none' ? [
                      {
                        name: 'isSubFilter',
                        type: 'boolean',
                        label: '明細単位で検索する',
                      },
                    ] : []
                  ),
                  ...(
                    dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                      {
                        name: 'subItemNamesForFilter',
                        type: 'multiSelect',
                        options: subItemNameOptions,
                        label: '明細名',
                        props: { width: 300, }
                      },
                    ] : []
                  ),
                ],
                [
                  {
                    name: 'budgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'comparedBudgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '比較予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'aggregationType',
                    type: 'select',
                    options: aggregationTypeOptions,
                    label: '集計種別',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
              ]}
            />
          </div>
          <div className="mb-3 d-flex justify-content-end align-items-end gap-1">
            <Button color="secondary" onClick={fetchLastTrials} disabled={isFetchingLastTrials}>
              網羅性チェック
            </Button>
            <BudgetButton {...{ period, company, accountItems, }} />
            {
              user.admin && (
                <ExportButton rows={oldBudgetRows} label="旧予算" fileName={`旧予算_${period}.csv`}  />
              )
            }
            <Button color="secondary" onClick={onClickExport.bind(null, { isAll: true })} disabled={isExporting}>
              全部門
              <span className={classnames('ml-1 fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
            </Button>
            <Button color="secondary" onClick={onClickExport.bind(null, { isAll: false, })} disabled={isExporting}>
              <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
            </Button>
            <CommentsDropdown company={company} companyId={companyId} currentUser={user} comments={comments.filter(_ => _.yearMonth.toString() === targetMonth.toString())} usersById={keyBy(members, 'id')} onClickComment={onClickComment(rows)} />
          </div>
          {rows.filter(_ => !_.isSubRow).some(_ => _.summaryColumns.find(_ => _.name === 'diff' && abs(_.amount) > 0)) && <div className="alert alert-danger">差額があります</div>}
          <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
            <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
              <thead className="text-center" style={{ lineHeight: '20px' }}>
                <tr>
                  <th className="text-left border-right p-0" style={{ fontSize: '80%', }}>
                    <ResizableBox className="p-2 d-flex align-items-center" width={COLUMN_WIDTH * 2.5} height={67} axis="x" resizeHandles={['e']} onResize={(e, _) => setLeftColumnWidth(_.size.width)}>
                      {
                        dimension !== 'none' && (
                          <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={subRowsVisibilities} setVisibilities={setAllSubRowsVisibilities} />
                        )
                      }
                      &nbsp;
                    </ResizableBox>
                  </th>
                  {
                    filteredBudgetSubjects.map(({ id, name, }) => {
                      return (
                        <th key={id} style={{ width: COLUMN_WIDTH, height: 70, }}>
                          <Link to={`/companies/${companyId}/monthlyBudgets?${qs.stringify({ ...pick(queryParams, ['period', 'targetMonth']), budgetSubjectIds: [id], tabKey: [budgetSubjectType, documentType].join('__'), })}`} style={{ height: '100%', overflow: 'hidden', display: 'block', }}>
                            {name}
                          </Link>
                        </th>
                      );
                    })
                  }
                  <th style={{ width: COLUMN_WIDTH, height: 70 }}>合計</th>
                  <th style={{ width: COLUMN_WIDTH, height: 70, fontSize: '11px' }}>使用しているfreee項目の合計</th>
                  <th style={{ width: COLUMN_WIDTH, height: 70 }}>試算表残高</th>
                  <th style={{ width: COLUMN_WIDTH, height: 70 }}>差額</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredRows.map((row) => {
                    const { isSubRow, itemKey, key, } = row;
                    const showsSubRows = (dimension !== 'none' && !!subRowsVisibilities[itemKey]);
                    if(isSubRow && !showsSubRows) return null;

                    const breakdownItemsLimitRate = subRowsBreakdownItemsLimitRates[itemKey] || 1;
                    const toggleSubRows = setSubRowsVisibilities.bind(null, itemKey, !showsSubRows);
                    const showMoreSubRows = setSubRowsBreakdownItemsLimitRates.bind(null, itemKey, breakdownItemsLimitRate + 1);
                    return <Row key={key} pageProps={props} row={row} {...{ company, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, leftColumnWidth, }} />
                  })
                }
              </tbody>
            </Table>
            <OverlayLoading isOpen={isSettingRows} />
          </div>
        </div>
      </div>
    </div>
  );
}
